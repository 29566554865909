:focus {
  outline-color: #59b200;
  outline-width: 0;
}

form textarea:focus,
form select:focus,
form button:focus,
form input:focus {
  outline-width: 2px;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
span,
div,
input,
textarea,
button {
  box-sizing: border-box;
  font-family: 'GT-Walsheim';
}

/* appearance: none is required to remove the shadow from the number input */
input[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loading-shimmer {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: table-cell;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

[data-reach-dialog-overlay] {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 3;
}

[data-reach-dialog-content] {
  background: none;
}

[data-reach-dialog-content]:before {
  content: '';
  position: absolute;
  transform: translate(-20px, 20px);
  width: 100%;
  height: 100%;
}

[data-reach-dialog-content][data-dialog-content-neutral]:before {
  background: #FF2779;
}

[data-reach-dialog-content][data-dialog-content-destructive]:before {
  background: #FF2779;
}

@media (max-width: 750px) {
  [data-reach-dialog-content] {
    max-width: none !important;
    width: 95vw !important;
  }
}

.overlay-blur {
  filter: blur(3px);
  overflow: hidden;
  height: 100vh;
}

[data-reach-menu-button] {
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
}

[data-reach-menu-list] {
  display: block;
  top: 100%;
  right: 0;
  z-index: 2;
  min-width: 10rem;
  width: 100%;
  margin: 0.125rem 0 1rem 0;
  padding: 0;
  font-size: 0.9rem;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 0 0 1px rgba(117, 117, 117, 0.4), 0 2px 10px rgba(3, 18, 42, 0.15);
  border-radius: 4px;
  transform: translate3d(0px, 0.5rem, 0px);
  overflow-y: auto;
  border: none;
  animation: menu-list-fade 200ms ease-in-out;
}

@keyframes menu-list-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

[data-reach-menu-item] {
  color: rgba(0, 0, 0, 0.85);
  padding: 0.5rem 1rem;
}

[data-reach-menu-item][data-selected] {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.85);
}

.ReactVirtualized__List {
  outline: none;
}

[data-reach-combobox-popover] {
  position: absolute;
  width: 100%;
  top: 100%;
  max-height: 16rem;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  outline: 0;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(117, 117, 117, 0.4), 0 2px 10px rgba(3, 18, 42, 0.15);
  z-index: 1;
  border: none;
}

[data-reach-combobox-option] {
  position: relative;
  cursor: pointer;
  line-height: 2em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.9rem;
  padding: 0.8rem 1.1rem;
}

/* the string portions that match what the user has typed */
[data-user-value] {
  font-weight: 500;
  color: #FF2779;
  letter-spacing: -0.01rem;
}
/* the string portions are suggested */
[data-suggested-value] {
  font-weight: normal;
  letter-spacing: -0.01rem;
}

[data-reach-tooltip] {
  margin-right: 2rem;
  z-index: 2;
  line-height: 1.25;
  box-shadow: 0 0 0 1px rgba(117, 117, 117, 0.4), 0 2px 10px rgba(3, 18, 42, 0.15);
  z-index: 4;
}

@media (max-width: 1899px) {
  html {
    font-size: 97.5%;
  }
}

@media (max-width: 1699px) {
  html {
    font-size: 95%;
  }
}

@media (max-width: 1499px) {
  html {
    font-size: 92.5%;
  }
}

@media (max-width: 1299px) {
  html {
    font-size: 90%;
  }
}

@media (max-width: 1099px) {
  html {
    font-size: 87.5%;
  }
}

@media (max-width: 800px) {
  html {
    font-size: 100%;
  }
}

.Toastify__toast {
  border-radius: 4px;
}

.Toastify__toast--success {
  background-color: #59b200;
}

.Toastify__toast-body {
  padding-left: 1rem;
}

.wistia_popover_overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.wistia_responsive_padding {
  border: 2px solid #cccccc;
  transition: 0.2s ease-in-out;
}

.wistia_responsive_padding:hover {
  box-shadow: rgba(255, 255, 255, 0.3) 0px 0px 15px 5px;
}
